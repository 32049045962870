import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTiktok,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="footer-col">
            <h4>getchmeva.com</h4>
            <ul>
              <li>
                <a>&copy; 2023 GETchmeva. All Rights Reserved.</a>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Email</h4>
            <ul>
              <li>
                <a href="mailto:getchmevams@gmail.com">getchmevams@gmail.com</a>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Follow us</h4>
            <div className="social-links">
              <a
                href="https://www.facebook.com/profile.php?id=61555736642323"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="https://www.instagram.com/get.chmeva/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://www.tiktok.com/@getchmeva?_t=8jPTG1NG7N9&_r=1&fbclid=IwAR3LueD5zKT6HFVff3D_tzC6oJrkMuW-DuS1ty9x2hmlL49lKkDYdishNNQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTiktok} />
              </a>
              <a href="#">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
          </div>
          <div className="footer-col">
            <h4>Developed by</h4>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/bughub_/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="developed">
                    <a>@bughub_</a>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
