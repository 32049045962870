import React, { useEffect } from "react";
import iphone from "../assets/iPhone.svg"
import appstore from "../assets/tempAppstore.svg"
import android from "../assets/tempAppstore2.svg"
import { useTranslation } from 'react-i18next';


export default function Home({language}){
  const { t } = useTranslation();

  return(
    <div className="main">
      <div className="hero">
        <div className="md:px-20">
          <h1 className="custom-heading" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}> <span className="text-primary" style={{ fontFamily: "Poppins-Regular" }}>GETchmeva </span>{t('your personal grocery assistant')}</h1>
          <p className="mt-5 text-1.5xl text-black" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t('Available soon on these platforms!')}</p>
          <div className="download">
            <img src={appstore} alt="" className="md:w-44 md:h-auto sm:w-40 sm:h-auto cursor-default" />
            <img src={android} alt="" className="md:w-44 md:h-auto sm:w-40 sm:h-auto cursor-default" />
          </div>
        </div>
        <img src={iphone} alt="" className="levitating-img" />
      </div>
    </div>
  );
}
