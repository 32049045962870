import React from "react";
import img1 from "../assets/2.jpeg"
import { useTranslation } from 'react-i18next';
import AOS from "aos"
import 'aos/dist/aos.css'
import { useEffect } from 'react';


export default function About({language}){
    const { t } = useTranslation();

    useEffect(()=>{
        AOS.init({duration: 2000})
      }, [])

    return(
            <div className="aboutMain">
                <div className="custom-div" data-aos="zoom-in-up">
                    <h2 className="text-primary font-semibold text-2.5xl">GETchmeva</h2>
                    <h1 className="text-black font-bold" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("Our Journey")}</h1>
                    <p style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("Our team united around a common goal in 2023 - we wanted to create a convenient and technically sound application to help people who need help choosing food products. Despite being on different continents, the team remains committed to the goal and will offer you a customized product very soon.")}</p>   
                </div>
                <img src={img1} className="custom-img" data-aos="zoom-in-up"/>
            </div>
    )
}