import "./App.css";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import HowItWorks from "./components/HowItWorks";
import Testimonials from "./components/Testimonials";
import NotifySection from "./components/Notify";
import Footer from "./components/Footer";
import ProductCard from "./components/ProductCard";
import { useState } from "react";
import i18n from "./i18next";
import React from "react";
import About from "./components/About";
import { Route, Routes, useLocation } from "react-router-dom";
import Faq from "./components/FAQ";
import Vis from "./components/Vision";
import Team from "./components/Team";
import { useEffect } from "react";


function App() {
  const [language, setLanguage] = useState("En");
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const path = location.pathname;

  const changeLanguageFunction = () => {
    console.log("language changed");
    language === "En" ? i18n.changeLanguage("Ka") : i18n.changeLanguage("En");
    language === "En" ? setLanguage("Ka") : setLanguage("En");
    console.log(language);
  };

  useEffect(() => {
    // Scroll to the top whenever the route changes
    window.scrollTo(0, 0);
    setIsMenuOpen(false);
  }, [location.pathname]);

  return (
    <>
      <Navbar
        changeLanguageFunction={changeLanguageFunction}
        language={language}
        
      />
      <div style={path.includes("/about") ? { display: "none" } : {}}>
        <Home language={language} />
        <HowItWorks language={language} />
        <ProductCard language={language} />
        <Testimonials language={language} />
        <NotifySection language={language} />
        <Footer />
      </div>
      
      <div style={!path.includes("/about") ? { display: "none" } : {}}>
      <Routes>
        <Route path="/home" element={""} />
        <Route path="/about" element={<About language={language} />} />
      </Routes>
      <Vis language={language}/>
      <Team language={language}/>
      <div>
        <Faq language={language}/>
      </div>
      <Footer />
      </div>


    </>
  );
}

export default App;
